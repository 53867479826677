/* You can add global styles to this file, and also import other style files */

// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';
// forms
.use-validation-colors {
  // material invalid input

  mat-form-field.ng-invalid.ng-touched,
  mat-form-field.ng-valid.ng-dirty.invalid-input,
  mat-form-field.invalid-input {

    .mat-form-field-outline-thick {
      border-color: transparent !important;
    }

    .mat-form-field-outline {
      color: #E11F42 !important;
      .mat-form-field-outline-gap {
        width: unset !important;
      }
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: #E11F42;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color:    #E11F42;
      opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:  #E11F42;
      opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:    #E11F42;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color:    #E11F42;
    }

    ::placeholder { /* Most modern browsers support this now. */
      color:    #E11F42;
    }

    mat-label {
      color: #E11F42;
    }
  }

  // end


  // material invalid input

  mat-form-field.ng-valid.ng-dirty {

    .mat-select-min-line {
      color: #00988F !important;
    }

    .mat-form-field-outline-thick {
      border-color: transparent !important;
    }

    .mat-form-field-outline {
      color: #00988F !important;

      .mat-form-field-outline-gap {
        width: unset !important;
      }
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: #00988F;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color:    #00988F;
      opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:  #00988F;
      opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:    #00988F;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color:    #00988F;
    }

    ::placeholder { /* Most modern browsers support this now. */
      color:    #00988F;
    }

    mat-label {
      color: #00988F;
    }
  }

  // end

  mat-label {
    background-color: white;
    padding: 0 5px;
    color: var(--neutral-dark);
  }

  mat-optgroup .mat-optgroup-label {
    color: var(--cor-base) !important;
    //styleName: Body/X-Small SemiBold;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }

}

@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
}

html,
body {
  height: 100%;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  font-size: 14px;
}

mat-form-field {
  width: 100%;

  .mat-form-field-wrapper {
    // .mat-form-field-appearance-outline .mat-form-field-prefix {
    // //   top: -1px !important;
    // }
    .mat-form-field-appearance-outline .mat-form-field-outline {
      border-radius: 12px !important;
      color: black;
    }
  }
}

button.filled {
  background-color: #750403;
  border-radius: 100px;
  height: 48px;
  color: white !important;
  padding: 24px !important;
}

button.outlined {
  background-color: #75040314;
  border-radius: 100px;
  border: 1px solid #8C716C;
  height: 48px;
  color: #750403 !important;
  padding: 24px !important;
}

.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #931e15 !important;
}

mat-form-field label {
  color: #59413e !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
}

mat-form-field.mat-form-field-should-float label {
  color: #59413e !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 0px !important;
  font-family: Poppins !important;
  font-weight: normal !important;
  margin-top: 0px !important;
  color: rgb(119, 117, 118) !important;
}

::ng-deep .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.4em 0px !important;
}
::ng-deep .mat-form-field-label-wrapper {
  top: -1.5em;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}

/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #59413e !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-hover-icon-color: #750403;
  --mdc-radio-selected-icon-color: #750403;
  --mdc-radio-selected-pressed-icon-color: #750403;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #750403;
}

// /* Change the border width */
// .mdc-text-field--outlined:not(.mdc-text-field--disabled)
//   .mdc-notched-outline
//   .mdc-notched-outline__leading,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled)
//   .mdc-notched-outline
//   .mdc-notched-outline__notch,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled)
//   .mdc-notched-outline
//   .mdc-notched-outline__trailing {
//   border-width: 1.5px !important;
// }

// /* Change the border focused color */
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
//   .mdc-notched-outline__leading,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
//   .mdc-notched-outline__notch,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
//   .mdc-notched-outline__trailing {
//   border-color: #59413E !important;
//   border-width: 3px !important;
// }

// .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
//     color: var(--mdc-outlined-text-field-label-text-color);
// }

// .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
//     border-color: var(--mdc-outlined-text-field-error-label-text-color) !important;
//     color: var(--mdc-outlined-text-field-error-label-text-color) !important;
// }

// /* Change the border hover color */
// .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
//   .mdc-notched-outline
//   .mdc-notched-outline__leading,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
//   .mdc-notched-outline
//   .mdc-notched-outline__notch,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
//   .mdc-notched-outline
//   .mdc-notched-outline__trailing {
//   border-color: #8C716C !important;
// }
// forms

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-focus-icon-color: #750403 !important;
  --mdc-checkbox-selected-hover-icon-color: #750403 !important;
  --mdc-checkbox-selected-icon-color: #750403 !important;
  --mdc-checkbox-selected-pressed-icon-color: #750403 !important;
}

.toggle {
  display: flex;
  align-items: center;
  gap: 24px;
}
.toggle-btn {
  display: inline-block;
  outline: 0;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}
.toggle-btn:focus::after,
.toggle-btn:active::after {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08),
    inset 0px 0px 0px 1.5px #9c9c9c;
}
.toggle-btn::after {
  left: 0;
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  border-radius: 2em;
  background: #fbfbfb;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.toggle-btn.toggle-btn-on::after {
  left: 50%;
}
.toggle-btn.toggle-btn-on {
  background: #86d993;
}
.toggle-btn.toggle-btn-on:active {
  box-shadow: none;
}
.toggle-btn.toggle-btn-on:active::after {
  margin-left: -0.8em;
}
.toggle-btn:active::after {
  padding-right: 0.8em;
}
.toggle-input {
  display: none;
}
